import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import { ThemeProvider } from 'styled-components'
import Icons from '@icons/icons'
import { getAddressString, getDeliveryIconNameFromMethod, getDeliveryNameFromMethod, getPaymentMethodName } from '@components/views/checkoutPage/utils/common'
import { StyledCheckoutConfirm } from '@components/views/checkoutPage/components/checkoutConfirm/styles/checkoutConfirm.styled'

import zhde from '../../../../../assets/img/zhde.png'
import pek from '../../../../../assets/img/pek.png'
import { commonStyle } from '../../../../../styles'

export function CheckoutConfirm() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const deliveryMethod = useSelector((state: IStore) => state.checkout.receiveInformation.deliveryMethod)
  const receiveInformation = useSelector((state: IStore) => state.checkout.receiveInformation)
  const productType = useSelector((state: IStore) => state.checkout.productType)

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutConfirm>
        <div className="confirm-container confirm-contact">
          <p>Контактная информация</p>

          <div className="contacts-container">
            <p className="contact-text">Имя: {receiveInformation.receiver.name}</p>
            <p className="contact-text">Телефон: {receiveInformation.receiver.phoneNumber}</p>
            <p className="contact-text">Предпочитаемый способ связи: {receiveInformation.contactInfo.method}</p>
            {
              receiveInformation.contactInfo.method &&
              receiveInformation.contactInfo.info &&
              <p className="contact-text">Контакт: {receiveInformation.contactInfo.info}</p>
            }
          </div>
        </div>

        <div className="confirm-container confirm-delivery">
          <p>Способ доставки</p>

          <div className="delivery-row">
            <div className="delivery-cart">
              {getDeliveryIconNameFromMethod(deliveryMethod).name === 'zhde' && <img src={zhde} width="20" height="20" alt="zhde" />}
              {getDeliveryIconNameFromMethod(deliveryMethod).name === 'pek' && <img src={pek} width="20" height="20" alt="pek" />}
              {getDeliveryIconNameFromMethod(deliveryMethod).type === 'svg' && <Icons name={getDeliveryIconNameFromMethod(deliveryMethod).name} color="#fff" size="20" className="icon" />}
              <p>{getDeliveryNameFromMethod(deliveryMethod)}</p>
            </div>
          </div>

          <div className="address-row">
            <p className="address-text">{getAddressString(receiveInformation, productType)}</p>
          </div>
        </div>

        <div className="confirm-container confirm-payment">
          <p>Оплата</p>

          <div className="payment-row">
            <p className="payment-text">{getPaymentMethodName(receiveInformation.paymentMethod)}</p>
          </div>
        </div>
      </StyledCheckoutConfirm>
    </ThemeProvider>
  )
}
