import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IStore, useAppDispatch } from '@store/store'
import { Button } from '@mui/material'
import { ThemeProvider } from 'styled-components'
import Icons from '@icons/icons'
import { getCartTotalWeight } from '@components/views/teaPage/components/products/components/tableView/utils/common'
import { StyledCheckoutDelivery } from '@components/views/checkoutPage/components/checkoutDelivery/styles/checkoutDelivery.styled'
import { DeliveryMethod } from '@endpoints/endpoints/checkout/type'
import { updateField } from '@store/checkout'
// @ts-ignore
import { Routers } from '@types/routers'

import zhde from '../../../../../assets/img/zhde.png'
import pek from '../../../../../assets/img/pek.png'
import { commonStyle } from '../../../../../styles'

export function CheckoutDelivery() {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
  }

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const MIN_WEIGHT_DELIVERY = 50 // Минимальный вес (кг) доствки для ЖелДорЭкспедиции и ПЭК
  const DEFAULT_CITY = 'Санкт-Петербург' // Город для самовывоза
  const DEFAULT_STREET = 'Захарьевская' // Улица для самовывоза
  const DEFAULT_APARTMENT_NUMBER = '29' // Номер дома для самовывоза

  const deliveryMethod = useSelector((state: IStore) => state.checkout.receiveInformation.deliveryMethod)
  const cart = useSelector((state: IStore) => state.cart.items)
  const productType = useSelector((state: IStore) => state.products.filters.productType)
  const receiveInformation = useSelector((state: IStore) => state.checkout.receiveInformation)

  // Вес нужен для проверки способов отправки
  const cartWeight = productType === 'SPB' ? getCartTotalWeight(cart, 'СПБ').toFixed(3) : getCartTotalWeight(cart).toFixed(3)

  // Если пользователь вернулся назад и уменьшил вес корзины до 50 кг, то нужно изменить способ доставки
  useEffect(() => {
    const isWeightLessMin = +cartWeight < MIN_WEIGHT_DELIVERY
    const isPekOrZhde = deliveryMethod === 'PEC' || deliveryMethod === 'ZHELDOR_EKSPEDITSIYA'
    if (isWeightLessMin && isPekOrZhde) {
      dispatch(updateField({ fieldPath: 'receiveInformation.deliveryMethod', value: '' }))
    }
  }, [cartWeight])

  const setDeliveryMethod = (delivery: DeliveryMethod) => {
    // Для курьера вводим дефолтный адрес
    if (delivery === 'COURIER') {
      dispatch(updateField({ fieldPath: 'receiveInformation.address.city', value: DEFAULT_CITY }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.street', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.apartmentNumber', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.buildingNumber', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.flatNumber', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.deliveryId', value: undefined }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.comment', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.contactInfo.method', value: undefined }))
    } else if(delivery === 'PICKUP') {
      // Для самовывоза вводим дефолтный адрес и сбрасываем адрес доставки
      dispatch(updateField({ fieldPath: 'receiveInformation.address.city', value: DEFAULT_CITY }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.street', value: DEFAULT_STREET }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.apartmentNumber', value: DEFAULT_APARTMENT_NUMBER }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.buildingNumber', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.flatNumber', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.comment', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.contactInfo.method', value: undefined }))
    } else {
      // Для всех остальных способов доставки при смене сбрасываем все данные по адресу доставки
      dispatch(updateField({ fieldPath: 'receiveInformation.address.city', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.street', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.apartmentNumber', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.buildingNumber', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.flatNumber', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.deliveryId', value: undefined }))
      dispatch(updateField({ fieldPath: 'receiveInformation.address.comment', value: '' }))
      dispatch(updateField({ fieldPath: 'receiveInformation.contactInfo.method', value: undefined }))
    }

    dispatch(updateField({ fieldPath: 'receiveInformation.deliveryMethod', value: delivery }))
  }

  const disabledNextButton = () => {
    return !receiveInformation.deliveryMethod
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutDelivery>
        <h2>Выберите способ доставки</h2>

        <div className='delivery-row'>
          <div className='cart-container'>
            <button
              className={`delivery-cart ${deliveryMethod === 'CDEK' && 'active'}`}
              onClick={() => setDeliveryMethod('CDEK')}
            >
              <Icons name='sdek' color='#fff' size='20' className='icon' />
              <p>СДЭК до отделения</p>
            </button>

            <button
              className={`delivery-cart ${deliveryMethod === 'CDEK_DOOR' && 'active'}`}
              onClick={() => setDeliveryMethod('CDEK_DOOR')}
            >
              <Icons name='sdek' color='#fff' size='20' className='icon' />
              <p>СДЭК до двери</p>
            </button>
          </div>
        </div>

        <div className='delivery-row'>
          <p className='delivery-row-name'>по Санкт-Петербургу:</p>

          <div className='cart-container'>
            <button
              className={`delivery-cart ${deliveryMethod === 'COURIER' && 'active'}`}
              onClick={() => setDeliveryMethod('COURIER')}
            >
              <Icons name='courier' color='#fff' size='20' className='icon' />
              <p>Курьер</p>
            </button>

            <button
              className={`delivery-cart ${deliveryMethod === 'PICKUP' && 'active'}`}
              onClick={() => setDeliveryMethod('PICKUP')}
            >
              <Icons name='self-pickup' color='#fff' size='20' className='icon' />
              <p>Самовывоз</p>
            </button>
          </div>
        </div>

        <div className='delivery-row'>
          <p className='delivery-row-name'>для заказов от 50 кг:</p>

          <div className="cart-container">
            <button
              className={`delivery-cart ${deliveryMethod === 'ZHELDOR_EKSPEDITSIYA' && 'active'}`}
              disabled={+cartWeight < MIN_WEIGHT_DELIVERY}
              onClick={() => setDeliveryMethod('ZHELDOR_EKSPEDITSIYA')}
            >
              <img src={zhde} width="24" height="24" alt="zhde" />
              <p>ЖелДорЭкспедиция</p>
            </button>

            <button
              className={`delivery-cart ${deliveryMethod === 'PEC' && 'active'}`}
              disabled={+cartWeight < MIN_WEIGHT_DELIVERY}
              onClick={() => setDeliveryMethod('PEC')}
            >
              <img src={pek} width='24' height='24' alt='pek'/>
              <p>ПЭК</p>
            </button>
          </div>
        </div>

        <Button
          className='next-button'
          variant='contained'
          disabled={disabledNextButton()}
          onClick={disabledNextButton() ? () => {} : () => setRouter('/checkout/info')}
        >
          Далее
        </Button>
      </StyledCheckoutDelivery>
    </ThemeProvider>
  )
}
