import React, { useEffect, useMemo, useCallback } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { IStore } from '@store/store'
import { StyledTSelector } from '@components/views/teaPage/components/filters/styles/tSelector.styled'
import { TSelectorProps } from '@components/views/teaPage/components/filters/types/types'
import Icons from '@icons/icons'

import { commonStyle } from '../../../../../../styles'

export const TSelector = React.memo(function TSelector(props: TSelectorProps) {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = useMemo(() => ({
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }), [colorTheme])

  const [option, setOption] = React.useState<string | null>(props.initialValue)
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    const currentOption = props.options.find(item => item.value === props.initialValue)?.value

    if(currentOption) {
      setOption(currentOption)
    }
  }, [props.initialValue, props.options])

  const handleChange = useCallback((event: SelectChangeEvent<typeof option>) => {
    const selectItem = props.options.find(item => item.value === event.target.value)

    if(selectItem) {
      props.onChange(props.filterName, selectItem.value)
      setOption(selectItem.value)
    }
  }, [props.options, props.onChange, props.filterName])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const selectedItem = useMemo(() => props.options.find(item => item.value === option), [option, props.options])

  return (
    <ThemeProvider theme={theme}>
      <StyledTSelector>
        <button className={`selector ${props.isDisabled ? 'selector-disabled' : ''} ${props.className}`} onClick={handleOpen} disabled={props.isDisabled}>
          {props.iconName ? (
            <Icons name={props.iconName} color="#fff" size="20" className="icon" />
          ) : null}

          <div className="text">
            <p>
              {selectedItem
                ? selectedItem.name
                : (props.placeholder || 'Выберите опцию')}
            </p>

            {selectedItem?.boxSize && (
              <p className="box">{selectedItem.boxSize} шт</p>
            )}
          </div>

          {open && !props.isDisabled ? (
            <Icons name="arrow-up-grey" color="#fff" size="20" className="icon" />
          ) : (
            !open && !props.isDisabled && (
              <Icons name="arrow-down-grey" color="#fff" size="20" className="icon" />
            )
          )}
        </button>

        <FormControl>
          <Select
            id="open-select"
            value={option}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={handleChange}
          >
            {props.options.map(item => (
              <MenuItem value={item.value} key={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledTSelector>
    </ThemeProvider>
  )
})
