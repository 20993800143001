import { axiosInstance } from '@endpoints/axios'
import { ProductType } from '@endpoints/endpoints/products/types'
import { ReceiveInformation } from '@endpoints/endpoints/checkout/type'
import { Orders } from '@endpoints/endpoints/cart/type'
// @ts-ignore
import { OrderData } from '@types/cartTypes'

export const checkout = {
  async checkoutOrder(productType: ProductType, orders: Orders, receiveInformation: ReceiveInformation): Promise<void> {
    return axiosInstance.post(
      '/order',
      {
        priceType: productType,
        order: orders,
        receiveInformation: {
          name: receiveInformation.receiver.name,
          receiver: {
            fullName: receiveInformation.receiver.name,
            phoneNumber: receiveInformation.receiver.phoneNumber
          },
          address: {
            country: productType === 'SPB' ? 'Россия' : 'Китай',
            city: receiveInformation.address.city,
            street: receiveInformation.address.street,
            apartmentNumber: receiveInformation.address.apartmentNumber,
            buildingNumber: receiveInformation.address.buildingNumber,
            flatNumber: receiveInformation.address.flatNumber,
            comment: receiveInformation.address.comment
          },
          deliveryId: receiveInformation.address.deliveryId,
          deliveryMethod: receiveInformation.deliveryMethod,
          isDefault: false
        },
        paymentMethod: receiveInformation.paymentMethod,
        contactInfo: receiveInformation.contactInfo
      }
    )
  },
}
