import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore, useAppDispatch } from '@store/store'
import { StyledItemPrice } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemPrice/styles/itemPrice.styled'
import { ItemPriceProps } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemPrice/types/types'
import { getPriceForChina, getPriceForSpb } from '@components/views/teaPage/components/products/components/tableView/utils/common'
import { currencyToCurrency, formatNumber } from '@utils/common'
import { setItemPriceThunk } from '@store/shoppingСart'

import { commonStyle } from '../../../../../../../../../../../styles'

export function ItemPrice(props: ItemPriceProps) {
  const dispatch = useAppDispatch()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const productType = useSelector((state: IStore) => state.products.filters.productType)
  const currentProductNumber = useSelector((state: IStore) => state.cart.items[props.itemId]?.number)
  const currentUnit = useSelector((state: IStore) => state.cart.items[props.itemId]?.unit)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const [priceForChina, setPriceForChina] = useState<number>(0)

  const isSpbSalePrice = () => {
    return productType === 'SPB' && props.product.spbDiscountPrice
  }

  useEffect(() => {
    let price = 0 // То что отображаем в таблице

    if (productType === 'CHINA') {
      if (currentUnit === 'BOX') {
        // Если заказ коробка, то отобразить нужно цену со скидкой, а добавить в корзину полную цену
        // Т.к. скидка будет считаться позднее
        const unit = props.product.units.find(unitItem => unitItem.type === 'BOX')

        if (unit) {
          price = unit.priceForUnitWithoutSale
          setPriceForChina(unit.priceForUnit)
        }
      } else if (currentProductNumber && currentProductNumber * props.product.valueGram >= 5000) {
        // Мне пизда с такими обработками...
        // Если заказ больше 5 кг, то нужно посчитать его цену с учётм скилки за 5 кг
        // Но в этом случае в корзине я должен установить полную цену товара (т.к. из него будет вычитаться скидка)
        // А визуально нужно в компонент передать цену с уже учтённой скидкой
        price = getPriceForChina(currentProductNumber, props.product, currentUnit ? currentUnit : props.product.units[0].type)
        setPriceForChina(props.product.priceFiveKgCny)
      } else {
        price = getPriceForChina(currentProductNumber, props.product, currentUnit ? currentUnit : props.product.units[0].type)
        setPriceForChina(price)
      }
    } else if (productType === 'SPB') {
      price = getPriceForSpb(props.product, currentUnit ? currentUnit : props.product.units[0].type)
    }

    dispatch(setItemPriceThunk({ id: props.itemId, price })) // Добавляем цену в корзину
  }, [ currentProductNumber, currentUnit, dispatch ])

  // Логика установки цены:
  // Бэк отдаёт для каждого товары из price список единиц измерения.
  // Каждая единица измерения содержит в себе название, цену и скидку. Например, штука и коробка будут стоить по-разному.
  // По дефолту устанавливается первое значение из списка.
  // Если значение выбрано по-дефолту и не менялось, то берём первое значение из списка единиц измерения.
  // Если значение было изменено, то это сохранится в корзине и мы используем последнее значение.
  // Для Китая есть более сложная формула для расчёта цены.

  return (
    <ThemeProvider theme={theme}>
      <StyledItemPrice>
        <p className={`standard-price ${isSpbSalePrice() ? 'old-price' : ''}`}>
          { productType === 'CHINA' ? formatNumber(priceForChina) : formatNumber(props.product.units[0].priceForUnit) }
          { currencyToCurrency(props.product.currency) }
        </p>

        {isSpbSalePrice() && <p className="sale-price">
          { formatNumber(props.product.spbDiscountPrice ? +props.product.spbDiscountPrice : null) }
          { currencyToCurrency(props.product.currency) }
        </p>}
      </StyledItemPrice>
    </ThemeProvider>
  )
}
