import { useEffect, useState } from 'react'

export function useAppMobileAvailability(maxWidth = 700): boolean | null {
  const [isMobile, setIsMobile] = useState<null | boolean>(null)

  useEffect(() => {
    const checkWidth = () => {
      setIsMobile(window.innerWidth <= maxWidth)
    }
    checkWidth()

    window.addEventListener('resize', checkWidth)
    return () => window.removeEventListener('resize', checkWidth)
  }, [maxWidth])

  return isMobile
}
