import { ProductType } from '@endpoints/endpoints/products/types'
import { Orders } from '@endpoints/endpoints/cart/type'

export type DeliveryMethod = 'CDEK' | 'CDEK_DOOR' | 'PEC' | 'ZHELDOR_EKSPEDITSIYA' | 'COURIER' | 'PICKUP' | ''
export type PaymentMethod = 'BY_BILL' | 'BY_QR' | 'BY_CASH' | ''

export enum ContactMethod {
  VK = 'Вконтакте',
  TG = 'Телеграм',
  WHATSAPP = 'Whatsapp',
  MAIL = 'Почта'
}

export enum ContactValue {
  VK = 'VK',
  TG = 'TG',
  WHATSAPP = 'WHATSAPP',
  MAIL = 'MAIL',
}

export interface ReceiveInformation {
  receiver: {
    name?: string
    secondName?: string
    surname?: string
    phoneNumber?: string
  }
  address: {
    country?: string
    city?: string
    street?: string
    buildingNumber?: string
    apartmentNumber?: string
    flatNumber?: string
    deliveryId?: string
    comment?: string
  }
  paymentMethod?: PaymentMethod
  deliveryMethod?: DeliveryMethod
  isDefault?: boolean
  contactInfo: {
    contactMethod?: ContactValue,
    info?: string
  }
}

export interface ReceiveFinalInformation {
  priceType: ProductType
  orders: Orders
  receiveInformation: {
    name: string
    receiver: {
      fullName: string
      phoneNumber: string
    },
    address: {
      country: string
      city: string
      street: string
      apartmentNumber: string
      buildingNumber: string
      flatNumber: string
      comment: string
    },
    deliveryId: string
    deliveryMethod: DeliveryMethod,
    isDefault: true
  },
  paymentMethod: PaymentMethod
}
