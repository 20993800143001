import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import Icons from '@icons/icons'
import { getDeliveryIconNameFromMethod, getDeliveryNameFromMethod, isRequiredCheckoutField } from '@components/views/checkoutPage/utils/common'
import { IStore, useAppDispatch } from '@store/store'
import { updateField } from '@store/checkout'
import { ThemeProvider } from 'styled-components'
import { StyledCheckoutInfo } from '@components/views/checkoutPage/components/checkoutInfo/styles/checkoutInfo.styled'
import { TSelector } from '@components/views/teaPage/components/filters/components/tSelector'
import { ContactValue } from '@endpoints/endpoints/checkout/type'
import { ValueType } from '@components/views/teaPage/types/types'
// @ts-ignore
import { Routers } from '@types/routers'

import zhde from '../../../../../assets/img/zhde.png'
import pek from '../../../../../assets/img/pek.png'

import { commonStyle } from '../../../../../styles'

export function CheckoutInfo() {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
  }

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const DEFAULT_CITY = 'Санкт-Петербург' // Город для самовывоза и курьерской доставки

  const deliveryMethod = useSelector((state: IStore) => state.checkout.receiveInformation.deliveryMethod)
  const receiveInformation = useSelector((state: IStore) => state.checkout.receiveInformation)

  const communicationMethods = [
    { value: ContactValue.VK, name: 'Вконтакте' },
    { value: ContactValue.TG, name: 'Телеграм' },
    { value: ContactValue.WHATSAPP, name: 'Whatsapp' },
    { value: ContactValue.MAIL, name: 'Почта' },
  ]

  const initialCommunicationMethod = communicationMethods.find(method => {
    return receiveInformation.contactInfo.method === method.value
  })

  const paymentMethods = [
    { value: 'BY_BILL', label: 'по счёту (для ИП и юр. лиц)' },
    { value: 'BY_QR', label: 'по QR (для физ. лиц)' },
    { value: 'BY_CASH', label: 'наличными (при самовывозе)' },
  ]

  // Если пользователь вернулся назад и изменил категорию доставки с самовывоза на иную, то нужно изменить способ оплаты
  useEffect(() => {
    const isNotPickupDelivery = deliveryMethod !== 'PICKUP'
    const isCashPayment = receiveInformation.paymentMethod === 'BY_CASH'

    if (isNotPickupDelivery && isCashPayment) {
      dispatch(updateField({ fieldPath: 'receiveInformation.paymentMethod', value: 'BY_BILL' }))
    }
  }, [deliveryMethod])

  const handleContactMethodChange = (filterName: string, value: ValueType) => {
    dispatch(updateField({ fieldPath: 'receiveInformation.contactInfo.method', value }))
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    const { value } = event.target
    dispatch(updateField({ fieldPath: name, value }))
  }

  const disabledNextButton = () => {
    return (
      !receiveInformation.deliveryMethod ||
      !receiveInformation.receiver.name ||
      !receiveInformation.receiver.phoneNumber ||
      !receiveInformation.address.city ||
      !receiveInformation.address.street ||
      !receiveInformation.address.apartmentNumber ||
      !receiveInformation.paymentMethod ||
      !receiveInformation.contactInfo.method ||
      !receiveInformation.contactInfo.info
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutInfo>
        <div className='info-container delivery-info'>
          <p>Способ доставки</p>

          <div className="delivery-cart">
            {getDeliveryIconNameFromMethod(deliveryMethod).name === 'zhde' && <img src={zhde} width="20" height="20" alt="zhde" />}
            {getDeliveryIconNameFromMethod(deliveryMethod).name === 'pek' && <img src={pek} width="20" height="20" alt="pek" />}
            {getDeliveryIconNameFromMethod(deliveryMethod).type === 'svg' && <Icons name={getDeliveryIconNameFromMethod(deliveryMethod).name} color="#fff" size="20" className="icon" />}
            <p>{getDeliveryNameFromMethod(deliveryMethod)}</p>
          </div>
        </div>

        <div className='info-container contacts-info'>
          <p>Контактная информация</p>

          <div className='contacts-field'>
            <Box className='input-container'>
              <div className='contact-info info'>
                <TextField
                  autoComplete='new-password'
                  className={`input-info name ${(isRequiredCheckoutField('receiveInformation.receiver.name') && !receiveInformation.receiver.name) ? 'required' : ''}`}
                  value={receiveInformation.receiver.name ?? ''}
                  placeholder='ФИО'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.receiver.name')}
                />

                <TextField
                  type='number'
                  autoComplete='new-password'
                  className={`input-info phoneNumber ${(isRequiredCheckoutField('receiveInformation.receiver.phoneNumber') && !receiveInformation.receiver.phoneNumber) ? 'required' : ''}`}
                  value={receiveInformation.receiver.phoneNumber ?? ''}
                  placeholder='Телефон'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.receiver.phoneNumber')}
                />
              </div>

              <div className='communication-info info'>
                <TSelector
                  filterName='contact-method'
                  placeholder='Способ связи'
                  className={`input-info phoneNumber ${(isRequiredCheckoutField('receiveInformation.contactInfo.method') && !receiveInformation.contactInfo.method) ? 'required' : ''}`}
                  iconName=''
                  options={communicationMethods}
                  initialValue={initialCommunicationMethod?.value || null}
                  isDisabled={false}
                  onChange={handleContactMethodChange}
                />

                <TextField
                  type='text'
                  autoComplete='new-password'
                  className={`input-info contactInfo ${(isRequiredCheckoutField('receiveInformation.contactInfo.info') && !receiveInformation.contactInfo.info) ? 'required' : ''}`}
                  value={receiveInformation.contactInfo.info ?? ''}
                  placeholder='Контакт'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.contactInfo.info')}
                />
              </div>
            </Box>
          </div>
        </div>

        <div className='info-container address-info'>
          {deliveryMethod === 'CDEK' && <div className="address sdec-department">
            <p>Введите адрес отделения СДЭК</p>

            <div className="address-field">
              <Box className="input-container">
                <TextField
                  autoComplete="new-password"
                  className={`
                    input-info
                    city
                    ${(isRequiredCheckoutField('receiveInformation.address.city') && !receiveInformation.address.city) ? 'required' : ''}
                  `}
                  value={receiveInformation.address.city ?? ''}
                  placeholder="город"
                  color="primary"
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.city')}
                />

                <TextField
                  autoComplete="new-password"
                  className={`
                    input-info
                    street
                    ${(isRequiredCheckoutField('receiveInformation.address.street') && !receiveInformation.address.street) ? 'required' : ''}
                  `}
                  value={receiveInformation.address.street ?? ''}
                  placeholder="улица"
                  color="primary"
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.street')}
                />

                <TextField
                  autoComplete="new-password"
                  className={`
                    input-info
                    input-info-small
                    apartmentNumber
                    ${(isRequiredCheckoutField('receiveInformation.address.apartmentNumber') && !receiveInformation.address.apartmentNumber) ? 'required' : ''}
                  `}
                  value={receiveInformation.address.apartmentNumber ?? ''}
                  placeholder="дом"
                  color="primary"
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.apartmentNumber')}
                />

                <TextField
                  autoComplete="new-password"
                  className={`
                    input-info
                    input-info-small
                    buildingNumber
                    ${(isRequiredCheckoutField('receiveInformation.address.buildingNumber') && !receiveInformation.address.buildingNumber) ? 'required' : ''}
                  `}
                  value={receiveInformation.address.buildingNumber ?? ''}
                  placeholder="корпус"
                  color="primary"
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.buildingNumber')}
                />
              </Box>
            </div>
          </div>}

          {['CDEK_DOOR', 'COURIER', 'ZHELDOR_EKSPEDITSIYA', 'PEC'].includes(deliveryMethod) && <div className="address sdek-delivery courier zhde-delivery pek-delivery">
            <p>Адрес доставки</p>

            <div className='address-field'>
              <Box className='input-container'>
                {/*Для доставки курьером отображаем отдельное заблокированное поле города , т.к. доставка только по СПБ*/}
                {deliveryMethod === 'COURIER' && <TextField
                  autoComplete='new-password'
                  className='input-info city'
                  value={DEFAULT_CITY}
                  placeholder='город'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.city')}
                />}

                {deliveryMethod !== 'COURIER' && <TextField
                  autoComplete='new-password'
                  className={`input-info city ${(isRequiredCheckoutField('receiveInformation.address.city') && !receiveInformation.address.city) ? 'required' : ''}`}
                  value={receiveInformation.address.city ?? ''}
                  placeholder='город'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.city')}
                />}

                <TextField
                  autoComplete='new-password'
                  className={`input-info street ${(isRequiredCheckoutField('receiveInformation.address.street') && !receiveInformation.address.street) ? 'required' : ''}`}
                  value={receiveInformation.address.street ?? ''}
                  placeholder='улица'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.street')}
                />

                <TextField
                  autoComplete='new-password'
                  className={`input-info input-info-small apartmentNumber ${(isRequiredCheckoutField('receiveInformation.address.apartmentNumber') && !receiveInformation.address.apartmentNumber) ? 'required' : ''}`}
                  value={receiveInformation.address.apartmentNumber ?? ''}
                  placeholder='дом'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.apartmentNumber')}
                />

                <TextField
                  autoComplete='new-password'
                  className={`input-info input-info-small buildingNumber ${(isRequiredCheckoutField('receiveInformation.address.buildingNumber') && !receiveInformation.address.buildingNumber) ? 'required' : ''}`}
                  value={receiveInformation.address.buildingNumber ?? ''}
                  placeholder='корпус'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.buildingNumber')}
                />

                <TextField
                  autoComplete='new-password'
                  className={`input-info input-info-small flatNumber${(isRequiredCheckoutField('receiveInformation.address.flatNumber') && !receiveInformation.address.flatNumber) ? 'required' : ''}`}
                  value={receiveInformation.address.flatNumber ?? ''}
                  placeholder='кв/офис'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.flatNumber')}
                />
              </Box>

              <Box className='input-container'>
                {['ZHELDOR_EKSPEDITSIYA', 'PEC'].includes(deliveryMethod) && <TextField
                  autoComplete='new-password'
                  className={`input-info input-info-big deliveryId ${(isRequiredCheckoutField('receiveInformation.address.deliveryId') && !receiveInformation.address.deliveryId) ? 'required' : ''}`}
                  value={receiveInformation.address.deliveryId ?? ''}
                  placeholder='ID (если имеется)'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.deliveryId')}
                />}

                <TextField
                  autoComplete='new-password'
                  className={`input-info input-info-big comment ${['ZHELDOR_EKSPEDITSIYA', 'PEC'].includes(deliveryMethod) && 'small-comment'} ${(isRequiredCheckoutField('receiveInformation.address.comment') && !receiveInformation.address.comment) ? 'required' : ''}`}
                  value={receiveInformation.address.comment ?? ''}
                  placeholder='комментарий'
                  color='primary'
                  onChange={(event) => handleInputChange(event, 'receiveInformation.address.comment')}
                />
              </Box>
            </div>
          </div>}

          {deliveryMethod === 'PICKUP' && <div className='address warehouse'>
            <p>Адрес склада</p>

            <div className='address-field'>
              <p className='warehouse-text'>Адрес: г. Санкт-Петербург, Захарьевская 29, офис 5</p>
              <p className='warehouse-text'>Выдача заказов: пн-сб 11:00-19:30 по мск</p>
            </div>
          </div>}
        </div>

        <div className='info-container payments-info'>
          <p>Выберите способ оплаты</p>

          <div className='payment-fields'>
            <RadioGroup
              aria-labelledby='payment-method-label'
              name='paymentMethod'
              value={receiveInformation.paymentMethod}
              onChange={(event) => handleInputChange(event, 'receiveInformation.paymentMethod')}
            >
              {paymentMethods.map((method) => (
                <FormControlLabel
                  name='receiveInformation.paymentMethod'
                  key={method.value}
                  value={method.value}
                  disabled={method.value === 'BY_CASH' && deliveryMethod !== 'PICKUP'}
                  control={<Radio />}
                  label={method.label}
                />
              ))}
            </RadioGroup>
          </div>
        </div>

        <Button
          className='next-button'
          variant='contained'
          disabled={disabledNextButton()}
          onClick={disabledNextButton() ? () => {} : () => setRouter('/checkout/confirm')}
        >
          Далее
        </Button>
      </StyledCheckoutInfo>
    </ThemeProvider>
  )
}
