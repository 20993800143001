import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore, useAppDispatch } from '@store/store'
import { StyledItemCardPrice } from '@components/views/teaPage/components/products/components/tableView/components/tableCard/components/itemInfo/components/itemCardPrice/styles/itemCardPrice.styled'
import { ItemPriceProps } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemPrice/types/types'
import { getPriceForChina, getPriceForSpb } from '@components/views/teaPage/components/products/components/tableView/utils/common'
import { currencyToCurrency, formatNumber } from '@utils/common'
import { setItemPriceThunk } from '@store/shoppingСart'

import { commonStyle } from '../../../../../../../../../../../../../styles'

export function ItemCardPrice(props: ItemPriceProps) {
  const dispatch = useAppDispatch()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const productType = useSelector((state: IStore) => state.products.filters.productType)
  const currentProductNumber = useSelector((state: IStore) => state.cart.items[props.itemId]?.number)
  const currentUnit = useSelector((state: IStore) => state.cart.items[props.itemId]?.unit)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const [priceForChina, setPriceForChina] = useState<number>(0)
  const [priceForSpb, setPriceForSpb] = useState<number>(0)

  const isSpbSalePrice = () => {
    return productType === 'SPB' && props.product.spbDiscountPrice
  }

  const getPercentage = (fullPrice: number, salePrice: number | null) => {
    if(salePrice && salePrice) {
      return Math.round(100 - (salePrice / fullPrice * 100))
    }
  }

  useEffect(() => {
    let price = 0

    if (productType === 'CHINA') {
      price = getPriceForChina(currentProductNumber, props.product, currentUnit ? currentUnit : props.product.units[0].type)
      setPriceForChina(price)
    } else if (productType === 'SPB') {
      price = getPriceForSpb(props.product, currentUnit ? currentUnit : props.product.units[0].type)
      setPriceForSpb(price)
    }

    dispatch(setItemPriceThunk({ id: props.itemId, price }))
  }, [ currentProductNumber, currentUnit, dispatch ])

  //Логика установки цены:
  //Бэк отдаёт для каждого товары из price список единиц измерения.
  //Каждая единица измерения содержит в себе название, цену и скидку. Например, штука и коробка будут стоить по-разному.
  //По дефолту устанавливается первое значение из списка.
  //Если значение выбрано по-дефолту и не менялось, то берём первое значение из списка единиц измерения.
  //Если значение было изменено, то это сохранится в корзине и мы используем последнее значение.
  //Для Китая есть более сложная формула для расчёта цены.

  return (
    <ThemeProvider theme={theme}>
      <StyledItemCardPrice>
        {productType === 'CHINA' &&
          <p className="not-sale">
            { Math.round(priceForChina) }
            { currencyToCurrency(props.product.currency) }
          </p>
        }

        {productType === 'SPB' && !isSpbSalePrice() &&
          <p className="not-sale">
            { Math.round(priceForSpb) }
            { currencyToCurrency(props.product.currency) }
          </p>
        }

        {productType === 'SPB' && isSpbSalePrice() &&
          <div className='sale'>
            <div className="prices">
              <div className="prices-container">
                <div className="sale-price">
                  <p className="price">
                    { formatNumber(props.product.spbDiscountPrice ? +props.product.spbDiscountPrice : null) }
                    { currencyToCurrency(props.product.currency) }
                  </p>
                </div>

                <div className="standard-price">
                  <p className="price">
                    { formatNumber(Math.round(props.product.price)) }
                    { currencyToCurrency(props.product.currency) }
                  </p>
                </div>
              </div>

              <p className="percentage">
                <span className="text">Cкидка</span>
                <span className="number">{ getPercentage(props.product.price, props.product.spbDiscountPrice) }%</span>
              </p>
            </div>
          </div>
        }
      </StyledItemCardPrice>
    </ThemeProvider>
  )
}
