import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { IStore } from '@store/store'
import Icons from '@icons/icons'
import { StyledCartInfo } from '@components/header/components/menu/components/menuButtons/components/cartInfo/styles/cartInfo.styled'
import { CartInfoProps } from '@components/header/components/menu/components/menuButtons/components/cartInfo/types/types'
import { countryToCurrency, formatNumber } from '@utils/common'
import {
  getCartTotalPrice,
  getCartTotalWeight,
  getCommonCartSale,
  getDiscountItemsSales,
  getMaxPercentage,
} from '@components/views/teaPage/components/products/components/tableView/utils/common'
// @ts-ignore
import { Routers } from '@types/routers'

import { commonStyle } from '../../../../../../../../styles'


export function CartInfo(props: CartInfoProps) {
  const navigate = useNavigate()

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
  }

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const cart = useSelector((state: IStore) => state.cart.items)
  const productType = useSelector((state: IStore) => state.products.filters.productType)
  const cartCondition = useSelector((state: IStore) => state.cart.condition)
  const user = useSelector((state: IStore) => state.auth.user)

  const hasSpbProducts = Object.values(cart).filter(item => item.region === 'СПБ').length > 0 && productType === 'SPB'
  const hasChinaProducts = Object.values(cart).filter(item => item.region !== 'СПБ').length > 0 && productType !== 'SPB'

  const price = productType === 'SPB' ? getCartTotalPrice(cart, 'СПБ').toFixed(3) : getCartTotalPrice(cart).toFixed(3)
  const commonWeight = productType === 'SPB' ? getCartTotalWeight(cart, 'СПБ').toFixed(3) : getCartTotalWeight(cart).toFixed(3)

  const discountItemsSales = getDiscountItemsSales(cart, productType)

  const commonSale = getCommonCartSale(
    cart,
    productType,
    user,
    cartCondition ? getMaxPercentage(cartCondition[productType].specialConditions, (+price - discountItemsSales)) : undefined,
  )

  return (
    <ThemeProvider theme={theme}>
      {((hasSpbProducts && productType === 'SPB') || (hasChinaProducts && productType === 'CHINA')) && <StyledCartInfo>
        <div className="cart-price-weight" onClick={() => setRouter('cart')}>
          <div className="cart-button">
            <Icons name="basket" color="#fff" size="12" className="icon" />
          </div>

          <div className="cart-price">
            {formatNumber(+price - commonSale)}
            {countryToCurrency(productType)}
          </div>

          {!!commonSale && <div className="cart-sale">
            <p className="sale">Скидка</p>
            <p className="number">
              {formatNumber(Math.round(commonSale))}
              {countryToCurrency(productType)}
            </p>
          </div>}

          <div className="cart-weight">
            <p className="sum">Вес</p>
            <p className="weight">{(+commonWeight).toFixed(2)} кг</p>
          </div>
        </div>
      </StyledCartInfo>}
    </ThemeProvider>
  )
}
