import { DeliveryMethod, PaymentMethod, ReceiveInformation } from '@endpoints/endpoints/checkout/type'
import { ProductType } from '@endpoints/endpoints/products/types'

export const getDeliveryNameFromMethod = (method: DeliveryMethod) => {
  switch (method) {
  case 'CDEK':
    return 'СДЭК до отделения'
  case 'CDEK_DOOR':
    return 'СДЭК до двери'
  case 'COURIER':
    return 'Курьер'
  case 'PICKUP':
    return 'Самовывоз'
  case 'ZHELDOR_EKSPEDITSIYA':
    return 'ЖелДорЭкспедиция (от 50 кг)'
  case 'PEC':
    return 'ПЭК (от 50 кг)'
  default:
    return 'Неизвестный способо доставки'
  }
}

export const getDeliveryIconNameFromMethod = (method: DeliveryMethod): { name: string, type: string } => {
  switch (method) {
  case 'CDEK':
    return { name: 'sdek', type: 'svg' }
  case 'CDEK_DOOR':
    return { name: 'sdek', type: 'svg' }
  case 'COURIER':
    return { name: 'courier', type: 'svg' }
  case 'PICKUP':
    return { name: 'self-pickup', type: 'svg' }
  case 'ZHELDOR_EKSPEDITSIYA':
    return { name: 'zhde', type: 'img' }
  case 'PEC':
    return { name: 'pek', type: 'img' }
  default:
    return { name: 'null', type: 'img' }
  }
}

const requiredCheckoutField = [
  'receiveInformation.deliveryMethod',
  'receiveInformation.receiver.name',
  'receiveInformation.receiver.phoneNumber',
  'receiveInformation.address.city',
  'receiveInformation.address.street',
  'receiveInformation.address.apartmentNumber',
  'receiveInformation.paymentMethod',
  'receiveInformation.contactInfo.method',
  'receiveInformation.contactInfo.info',
]

export const isRequiredCheckoutField = (fieldName: string): boolean => {
  return requiredCheckoutField.includes(fieldName)
}

export const getAddressString = (receiveInformation: ReceiveInformation, productType: ProductType) => {
  return `
    Адрес: 
    ${productType === 'SPB' ? 'Россия,' : 'Китай,'} 
    ${receiveInformation.address.city ? receiveInformation.address.city + ',' : ''} 
    ${receiveInformation.address.street ? receiveInformation.address.street : ''} 
    ${receiveInformation.address.apartmentNumber ? receiveInformation.address.apartmentNumber : ''} 
    ${receiveInformation.address.buildingNumber ? ', ' + 'корпус ' + receiveInformation.address.buildingNumber : ''} 
    ${receiveInformation.address.flatNumber ? ', ' + 'кв. ' + receiveInformation.address.flatNumber : ''}
  `
}

export const getPaymentMethodName = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod) {
  case 'BY_BILL':
    return 'по счёту (для ИП и юр. лиц)'
  case 'BY_CASH':
    return 'наличными (при самовывозе)'
  case 'BY_QR':
    return 'по QR (для физ. лиц)'
  default:
    return 'Неизвестный способо оплаты'
  }
}
