import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { IStore, useAppDispatch } from '@store/store'
import { Button, Modal, Tooltip } from '@mui/material'
import { Orders } from '@endpoints/endpoints/cart/type'
import { CartDataProps } from '@components/views/cartPage/components/cartData/types/types'
import { countryToCurrency, formatNumber, productTypeToCity } from '@utils/common'
import { VIP_SPB_SALE } from '@utils/consts'
import { checkoutThunk, deleteAllItem, setCheckoutDoneStatus, setCheckoutProblemStatusThunk, setProblemOrderStatusThunk } from '@store/shoppingСart'
import { getCartTotalPrice, getCartTotalWeight, getCommonCartSale, getDiscountItemsSales, getMaxPercentage } from '@components/views/teaPage/components/products/components/tableView/utils/common'
import { CheckoutProblem } from '@components/views/cartPage/components/cartData/components/checkoutProblem/checkoutProblem'
import { CheckoutDone } from '@components/views/cartPage/components/cartData/components/checkoutDone/checkoutDone'
import { setAuthViewThunk } from '@store/auth'
import { Authorization } from '@components/authorization/authorization'
import { StyledCartData } from '@components/views/cartPage/components/cartData/styles/cartData.styled'
import { checkoutOrderThunk, deleteOrders, setOrderThunk, updateProductType } from '@store/checkout'
// @ts-ignore
import { ProblemOrderStatus } from '@types/cartTypes'
// @ts-ignore
import { Routers } from '@types/routers'

import { commonStyle } from '../../../../../styles'

export function CartData(props: CartDataProps) {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname

  const setRouter = (routerName: Routers) => {
    navigate(`/${routerName}`)
  }

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const cart = useSelector((state: IStore) => state.cart.items)
  const user = useSelector((state: IStore) => state.auth.user)
  const cartCondition = useSelector((state: IStore) => state.cart.condition)
  const productType = useSelector((state: IStore) => state.products.filters.productType)
  const checkoutDoneOpen = useSelector((state: IStore) => state.cart.isCheckoutDone)
  const checkoutProblemOpen = useSelector((state: IStore) => state.cart.isCheckoutProblem)
  const checkoutOrders = useSelector((state: IStore) => state.checkout.orders)

  const [authOpen, setAuthOpen] = useState(false)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
    tertiaryColor: commonStyle[colorTheme].tertiaryColor,
  }

  const price = productType === 'SPB' ? getCartTotalPrice(cart, 'СПБ').toFixed(3) : getCartTotalPrice(cart).toFixed(3)
  const commonWeight = productType === 'SPB' ? getCartTotalWeight(cart, 'СПБ').toFixed(3) : getCartTotalWeight(cart).toFixed(3)

  let minOrder = 0

  // Проверяем, является ли пользователь VIP. Если пользователь не VIP, то настраиваем минимальный заказ.
  if (user?.status !== 'VIP') {
    // Если существует условие корзины (cartCondition), проверяем его
    if (cartCondition && cartCondition[productType]) {
      // Устанавливаем минимальный заказ, если есть данные для данного типа продукта
      minOrder = cartCondition[productType].minOrderMoney
    }
  }

  // Скидка на скидочные позиции в СПБ
  const discountItemsSales = getDiscountItemsSales(cart, productType)

  const commonSale = getCommonCartSale(
    cart,
    productType,
    user,
    cartCondition ? getMaxPercentage(cartCondition[productType].specialConditions, (+price - discountItemsSales)) : undefined,
  )

  const specialSalePercentage = productType && cartCondition && cartCondition[productType].specialConditions.length
    ? getMaxPercentage(cartCondition[productType].specialConditions, (+price - discountItemsSales))
    : 0

  // Получаем цену за вычетом скидок за объём и за статус клиента
  const priceWithSale = +price - commonSale

  // Получаем цену со скидкой за заказ без учёта процента за объём заказа
  const priceWithSaleWithoutSalePercentage = +price - getCommonCartSale(cart, productType, user)

  // Открыть окно логина
  const handleLoginOpen = () => setAuthOpen(true)

  // Закрыть окно логина
  const handleLoginClose = () => {
    setAuthOpen(false)

    setTimeout(() => {
      dispatch(setAuthViewThunk('login'))
    }, 300)
  }

  // Используем для оформления из корзины
  const handleCheckoutFromCart = async () => {
    const orderStatusProblem = await checkOrder()

    if(!orderStatusProblem.status && !user) {
      handleLoginOpen()
      return
    }

    if(!orderStatusProblem.status) {
      const orders: Orders = []

      //Определяю товары для типа корзины
      const currentProductTypeItems = productType === 'SPB' ?
        Object.values(cart).filter(item => item.region === 'СПБ') : //Товары для СПБ
        Object.values(cart).filter(item => item.region !== 'СПБ') //Товары для Китая

      currentProductTypeItems.forEach(item => {
        if(item.item && item.number) {

          orders.push({
            art: item.item.art,
            type: item.unit ?? '',
            value: item.number
          })
        }
      })

      // Добавляем ордер в state
      dispatch(setOrderThunk(orders))

      if (productType === 'SPB') {
        // Если заказ из СПБ, то переходим на страницу оформления заказа
        goToCheckoutPage()
      } else if (productType === 'CHINA') {
        // Если заказ из Китая, то сразу отправляем его к менеджеру
        // Оформляем заказ
        await dispatch(checkoutThunk({ productType, orders: orders }))
        // Показываем окно успешного оформления товара
        dispatch(setCheckoutDoneStatus(true))
      }
    }
  }

  // Используем для оформления из оформления
  const handleCheckoutFromCheckout = async () => {
    updateProductType(productType)
    // Оформляем заказ c данными из оформления
    await dispatch(checkoutOrderThunk())
    // Показываем окно успешного оформления товара
    dispatch(setCheckoutDoneStatus(true))
  }

  const handleCheckoutProblemClose = () => {
    dispatch(setCheckoutProblemStatusThunk())
  }

  const handleCheckoutDoneClose = () => {
    // Очистить корзину после заказа
    dispatch(deleteAllItem())
    // Очистить корзину после заказа
    dispatch(deleteOrders())
    // Скрываем окно успешного оформления товара
    dispatch(setCheckoutDoneStatus(false))
  }

  const checkOrder = async (): Promise<ProblemOrderStatus> => {
    // Если стоимость заказа меньше минимальной стоимости заказа
    if (priceWithSale < minOrder) {
      return await dispatch(setProblemOrderStatusThunk({
        status: true,
        message: `Сумма заказа ${priceWithSale} ниже минимальной суммы заказа ${minOrder} ${countryToCurrency(productType)}`
      })).unwrap()
    }

    // Определяю товары для типа корзины (кроме коробок, для них шаг всегда 1)
    const currentProductTypeItems = productType === 'SPB' ?
      Object.values(cart).filter(item => item.region === 'СПБ') :
      Object.values(cart).filter(item => item.region !== 'СПБ')

    // Если количество товара не кратно шагу заказа товара
    for (const item of currentProductTypeItems) {
      if (
        item.number &&
        item.item && item.number % item.item.step !== 0 &&
        item.unit !== 'BOX'
      ) {
        return await dispatch(setProblemOrderStatusThunk({
          status: true,
          message: `Количество товара ${item.item.art} выбрано некорректно, т.к. не соответствует шагу заказа - ${item.item.step}`
        })).unwrap()
      }
    }

    return await dispatch(setProblemOrderStatusThunk({
      status: false,
      message: ''
    })).unwrap()
  }

  const goToCheckoutPage = () => {
    setRouter('checkout/delivery')
  }

  // Не показываем кнопку оформить, если мы на странице выбора доставки и ввода данных заказа
  const canShowCheckoutBottom = () => {
    return currentPath !== '/checkout/delivery' && currentPath !== '/checkout/info'
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCartData>
        <h2>Заказ</h2>

        <div className="info-container">
          {!props.isCartPage && <div className="info city">
            <p className="first">Город</p>
            <p className="second">{productTypeToCity(productType)}</p>
          </div>}

          <div className="info weight">
            <p className="first">Вес</p>
            <p className="second">{(+commonWeight).toFixed(2)} кг</p>
          </div>

          {props.isCartPage && <div className="info sum-without-sales">
            <p className="first">Cумма без скидки</p>
            <p className="second">{formatNumber(+price)} {countryToCurrency(productType)}</p>
          </div>}

          {/* Китай и если есть скидочные позиции */}
          {productType === 'CHINA' && commonSale > 0 && <div className="info specials-sales">
            <p className="first">Скидка</p>
            <p className="second">-{formatNumber(commonSale)} {countryToCurrency(productType)}</p>
          </div>}

          {/* СПБ и если есть скидочные позиции */}
          {productType === 'SPB' && discountItemsSales > 0 && <div className="info specials-sales">
            <p className="first">Скидка по позициям</p>
            <p className="second">-{formatNumber(discountItemsSales)} {countryToCurrency(productType)}</p>
          </div>}

          {/* СПБ и если заказ из SPB, то показываем скидку за объём товара (Причём клиент не должени быть VIP, т.к. у них своя скидка
          Эту скидку нужно считать от уже скидочной цены, если есть другие скидки, а не от начальной*/}
          {productType === 'SPB' && specialSalePercentage > 0 && user?.status !== 'VIP' && <div className="info specials-sales">
            <Tooltip title="Cкидка за объём: от 30k - 5%, от 50k - 7%, от 80k - 8%" arrow>
              <p className="first">Cкидка за объём заказа</p>
            </Tooltip>
            <p className="second">
              -{formatNumber(priceWithSaleWithoutSalePercentage * (specialSalePercentage / 100))} {countryToCurrency(productType)}
            </p>
          </div>}

          {/* СПБ и если пользователь VIP, то показываем скидку клиента
          Эту скидку нужно считать от уже скидочной цены, если есть другие скидки, а не от начальной*/}
          {productType === 'SPB' && user?.status === 'VIP' && (+price * VIP_SPB_SALE) > 0 && <div className="info client-sales">
            <Tooltip title="ВИП-скидка 10%." arrow>
              <p className="first">Cкидка клиента (ВИП)</p>
            </Tooltip>
            <p className="second">-{formatNumber((+price - discountItemsSales) * VIP_SPB_SALE)} {countryToCurrency(productType)}</p>
          </div>}

          <div className="info price">
            <p className="first">Итого</p>
            <p className="second">{formatNumber(priceWithSale)} {countryToCurrency(productType)}</p>
          </div>
        </div>

        {/* Оформление из страницы корзины */}
        {props.isCartPage && <div>
          {/* Если сумма заказа превышает минимальный заказ, то показывем кнопку оформить */}
          {(minOrder <= priceWithSale) &&
            <Button className="checkout" variant="contained" onClick={handleCheckoutFromCart}>
              Оформить
            </Button>}

          {/* Отключаем КИТАЙ из-за Китайского Нового года */}
          {/* Если сумма заказа меньше минимального, то показываем сумму, которую необходимо добрать до заказа */}
          {(minOrder > priceWithSale && productType === 'SPB') &&
            <Button className="checkout" variant="contained" disabled={minOrder > priceWithSale}>
              До заказа ещё {minOrder - priceWithSale} {countryToCurrency(productType)}
            </Button>}
        </div>}

        {/* Оформление из страницы заказа */}
        {(!props.isCartPage && canShowCheckoutBottom() && checkoutOrders.length) && <div>
          {/* Если сумма заказа превышает минимальный заказ, то показывем кнопку оформить */}
          {(minOrder <= priceWithSale) &&
          <Button className="checkout" variant="contained" onClick={handleCheckoutFromCheckout}>
            Оформить
          </Button>}
        </div>}

        {/* Если сумма заказа меньше минимального заказ, то показывем минимальный заказ */}
        {(props.isCartPage && minOrder > 0) && <div className="min-order">
          <p className="first">Минимальный заказ </p>
          <p className="second">{minOrder} {countryToCurrency(productType)}</p>
        </div>}

        {/* Модальное окно с проблемами оформления заказа */}
        <Modal
          open={checkoutProblemOpen}
          onClose={handleCheckoutProblemClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="checkout-problem-modal"
        >
          <div className="modal-content">
            <CheckoutProblem />
          </div>
        </Modal>

        {/* Модальное окно успешного оформления заказа */}
        <Modal
          open={checkoutDoneOpen}
          onClose={handleCheckoutDoneClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="checkout-problem-modal"
        >
          <div className="modal-content">
            <CheckoutDone onClose={handleCheckoutDoneClose} />
          </div>
        </Modal>

        {/* Модальное окно входа в аккаунт */}
        <Modal
          open={authOpen}
          onClose={handleLoginClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="registration-modal"
        >
          <div className="modal-content">
            <Authorization onClose={handleLoginClose} />
          </div>
        </Modal>
      </StyledCartData>
    </ThemeProvider>
  )
}
