import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import Icons from '@icons/icons'
import { IStore } from '@store/store'
import {
  ItemInfoProps,
} from '@components/views/teaPage/components/products/components/tableView/components/tableCard/components/imageSlider/types/types'
import {
  StyledImageSlider,
} from '@components/views/teaPage/components/products/components/tableView/components/tableCard/components/imageSlider/styles/imageSlider.styled'

import { commonStyle } from '../../../../../../../../../../../styles'

export function ImageSlider(props: ItemInfoProps) {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const imagesSrc = props.product.photosLinks

  const [mainImage, setMainImage] = useState<string | null>(null)
  const [offset, setOffset] = useState(0)

  const imagesPerPage = 3
  const currentImages = imagesSrc.slice(offset, offset + imagesPerPage)

  useEffect(() => {
    setMainImage(imagesSrc.filter(image => image === props.product.mainPhotoLink)[0])
  }, [imagesSrc])

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
    backgroundColor: commonStyle[colorTheme].backgroundColor,
  }

  const handlePrevClick = () => {
    setOffset(prevOffset => Math.max(prevOffset - 1, 0))
  }

  const handleNextClick = () => {
    setOffset(prevOffset => Math.min(prevOffset + 1, imagesSrc.length - imagesPerPage))
  }

  const setCurrentImage = (image: string) => {
    setMainImage(image)
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledImageSlider $imagesPerPage={imagesPerPage}>
        <div className="image-container">
          {!!imagesSrc.length && (
            <div className="image-exist">
              <div className="main-image">
                <img src={mainImage ? mainImage : ''} alt="Картинка(((" />
              </div>

              <div className="slider">
                <button className="arrow-up arrow" onClick={handlePrevClick} disabled={offset <= 0}>
                  <Icons
                    name="arrow-up-grey"
                    color={offset === 0 ? commonStyle.colors.grey400 : commonStyle[colorTheme].color}
                    size="24"
                    className="icon"
                  />
                </button>

                <div className={`images ${imagesSrc.length <= imagesPerPage ? 'images-center' : ''}`}>
                  {currentImages.map((image) => (
                    <div
                      className={`image ${image === mainImage ? 'select' : ''}`}
                      onClick={() => setCurrentImage(image)}
                      key={image}
                    >
                      <img src={image ? image : ''} alt="Картинка" />
                    </div>
                  ))}
                </div>

                <button className="arrow-down arrow" onClick={handleNextClick} disabled={(offset >= imagesPerPage || imagesSrc.length <= imagesPerPage)}>
                  <Icons
                    name="arrow-down-grey"
                    color={(offset >= imagesPerPage || imagesSrc.length <= imagesPerPage) ? commonStyle.colors.grey400 : commonStyle[colorTheme].color}
                    size="24"
                    className="icon"
                  />
                </button>
              </div>
            </div>
          )}

          {!imagesSrc.length && (
            <div className="image-not-exist">
              <Icons name="no-content" color={commonStyle[colorTheme].tertiaryColor} size="36" className="icon" />
            </div>
          )}
        </div>

      </StyledImageSlider>
    </ThemeProvider>
  )
}
