import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../../styles'

export const StyledCartInfo = styled.div.attrs(() => ({
  className: 'cart-info',
}))`
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 30px;
  padding: 0.25rem 0.5rem;
  background-color: ${() => commonStyle.colors.grey100};
  border-radius: ${() => commonStyle.radii.radius8};
  
  .cart-price-weight {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    cursor: pointer;
    
    .cart-price {
      font-size: ${() => commonStyle.fonts.fs14};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      white-space: nowrap;
    }
    
    .cart-sale {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 0.25rem;
      font-size: ${() => commonStyle.fonts.fs9};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      
      .sale {
        color: ${() => commonStyle.colors.grey400};
      }
      
      .number {
        color: ${() => commonStyle.colors.red};
      }
    }

    .cart-weight {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 0.25rem;
      width: fit-content;
      font-weight: ${() => commonStyle.fontStyles.fw600};

      .sum {
        color: ${() => commonStyle.colors.grey400};
        font-size: ${() => commonStyle.fonts.fs9};
      }

      .weight {
        text-wrap: nowrap;
        font-size: ${() => commonStyle.fonts.fs9};
      }
    }
  }
`
